import { UsersListProps, UsersIDsProps } from '@/types/Dashboard';
import { AxiosWrapperReturn } from '@/types/Model';

import Cookies from 'js-cookie';

export type userResponse = {
  status: string;
  data: { data: AxiosWrapperReturn };
};

export async function loginUser(data: any): Promise<AxiosWrapperReturn> {
  const response = await fetch('/api/login', {
    method: 'POST',
    body: JSON.stringify(data),
  });

  return handleResponse<userResponse>(response).then((data) => data);
}

export async function logoutUser(): Promise<AxiosWrapperReturn> {
  const response = await fetch('/api/logout', {
    method: 'POST',
  });

  return handleResponse<userResponse>(response).then((data) => data);
}

export async function forgetPasswordUser(data: any): Promise<AxiosWrapperReturn> {
  const response = await fetch('/api/users/forgetPassword', {
    method: 'POST',
    body: JSON.stringify(data),
  });

  return handleResponse<userResponse>(response).then((data) => data);
}

export async function resetPasswordUser(data: any): Promise<AxiosWrapperReturn> {
  const { token } = data;
  delete data.token;
  const response = await fetch('/api/users/forgetPassword', {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      token: `${token}`,
    },
  });

  return handleResponse<userResponse>(response).then((data) => data);
}

export async function signUpUser(data: any): Promise<AxiosWrapperReturn> {
  const response = await fetch('/api/signup', {
    method: 'POST',
    body: JSON.stringify(data),
  });

  return handleResponse<userResponse>(response).then((data) => data);
}
export async function getUsers({ page = 1, searchParam = '' }): Promise<AxiosWrapperReturn> {
  const response = await fetch(`/api/users?page=${page}&searchParam=${searchParam}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return handleResponse<userResponse>(response).then((data) => data);
}

export async function getUsersSelection({
  page = 1,
  searchParam = '',
}): Promise<AxiosWrapperReturn> {
  const response = await fetch(`/api/users/selection?page=${page}&searchParam=${searchParam}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return handleResponse<userResponse>(response).then((data) => data);
}

export async function getAccountById({ userId }: UsersListProps): Promise<AxiosWrapperReturn> {
  const response = await fetch('/api/users/byId', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      userId: `${userId}`,
    },
  });

  return handleResponse<userResponse>(response).then((data) => data);
}

export async function updateAccount(data: UsersListProps): Promise<AxiosWrapperReturn> {
  const { userId } = data;
  delete data.userId;
  const response = await fetch('/api/users', {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      userId: `${userId}`,
    },
  });

  return handleResponse<userResponse>(response).then((data) => data);
}

export async function updateAccountPassword(data: UsersListProps): Promise<AxiosWrapperReturn> {
  const { userId } = data;
  delete data.userId;
  const response = await fetch('/api/users/byId', {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      userId: `${userId}`,
    },
  });

  return handleResponse<userResponse>(response).then((data) => data);
}

export async function addAccount(data: UsersListProps): Promise<AxiosWrapperReturn> {
  const response = await fetch('/api/users', {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return handleResponse<userResponse>(response).then((data) => data);
}

export async function deleteMultipleAccounts(data: UsersIDsProps): Promise<AxiosWrapperReturn> {
  const response = await fetch('/api/users/delete', {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return handleResponse<userResponse>(response).then((data) => data);
}

export async function disableMultipleAccounts(data: UsersIDsProps): Promise<AxiosWrapperReturn> {
  const response = await fetch('/api/users/disable', {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return handleResponse<userResponse>(response).then((data) => data);
}

export async function resetMultipleAccounts(data: UsersIDsProps): Promise<AxiosWrapperReturn> {
  const response = await fetch('/api/users/resetpassword', {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return handleResponse<userResponse>(response).then((data) => data);
}

async function handleResponse<T>(response: Response): Promise<T> {
  if (!response.ok) {
    throw response;
  }
  const contentType = response.headers.get('Content-Type') || '';
  const isJson = contentType.includes('application/json');
  const data = isJson ? await response.json() : await response.text();
  return data as T;
}
